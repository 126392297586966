<template>
    <div>
        <articles-widget v-if="activeArticlesWidget"/>
        <learning-widget v-if="activeLearningWidget && !current_user.is_admin"/>
    </div>
</template>

<script>
    import {mapState} from "vuex"

    export default {
        name: 'WrapperMaterials',
        components: {
            ArticlesWidget : () => import('../widgets/Articles'),
            LearningWidget : () => import('../widgets/Learning'),
        },
        props: {
            activeArticlesWidget: {
                type: Boolean,
                required: false,
                default: false
            },
            activeLearningWidget: {
                type: Boolean,
                required: false,
                default: false
            }
        },
        created() {
            this.$store.dispatch('wrapper_materials/initialize');
        },
        computed: {
            ...mapState('wrapper_materials', [
              'favourites', 'old'
            ]),
            ...mapState('default_data', [
              'current_user'
            ]),
        },
    }
</script>

<style scoped>

</style>
